<template>
    <div style="background-color: #f5faff">
        <titleBar
        style="max-height: 50px;margin-bottom: 10px;"
        :username="currentUserName"
        :pageName="pageName"
        ></titleBar>
        <!-- Dialog Block Start -->
         <v-dialog v-model="noSpecializationFound"
         persistent max-width="300" :overlay="true">
         <div style="padding: 12px; background-color: white;">
             <div>
                 <span style="font-weight: 600;  margin-top: 8px;"> Currently No Specialization is available for CPI Consultation </span>
             </div>
             <div class="dialog-action-block">
                <v-btn style="background-color: #1467BF; color: white;" @click="redirectToCPIAppointment()">
                    Ok
                </v-btn>
             </div>   
         </div>
         </v-dialog>
         <!-- Dialog Block End -->
        <v-app>
            <div style="background-color: #f5faff; height: 100vh;">
                <v-row cols="12">
                    <v-col cols="2">
                        <sideNavBar :pageName="pageName"></sideNavBar>
                    </v-col>
                    <v-col cols="10" style="overflow:scroll; height:93vh;">
                        <div style="margin: 20px 20px 20px 50px ">
                            <div class="d-flex" style="justify-content: space-between;">
                                <div style="width: 30%" align="left">
                                    <span  style="font-weight: 800;  font-size: 12pt;text-transform: uppercase;   letter-spacing: 1px;" class="pl-2">
                                    <h3>Create Quick Help Appointment</h3>
                                    </span>
                                </div>
                            </div>
                            <v-form>
                                <div class="pa-4 backgorund-card">
                                    <div class="d-flex py-2">
                                        <div class="field-layout">
                                            <span>Select Country Code</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <div class="d-flex country-code-value mr-2" @click="updateCommunicationMedium('+95')" :class="selectedCountryCode === '+95' ? ' active-country' : 'country-code-value'">
                                                <div class="mr-1">
                                                    + 95
                                                </div>
                                                <div class="ml-1">
                                                    <img src="https://s3iconimages.mymedicine.com.mm/mm_falg.svg" alt="MM">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex py-2 align-baseline">
                                        <div class="field-layout">
                                            <span>Enter Birth Year</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <v-select
                                                v-model="selectedBirthYear"
                                                :items="yearsList"
                                                label="Select Year"
                                                solo
                                            ></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex py-2 align-baseline">
                                        <div class="field-layout">
                                            <span>Enter Phone Number</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <v-text-field solo label="Enter Phone Number without country code" v-model="phoneNumber" style="border-radius: 8px;"></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex py-2 my-4 align-baseline">
                                        <div class="field-layout">
                                            <span>Communication Medium</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <v-select 
                                            :items="listOfCommunicationMedium"
                                            solo
                                            style="height: 40px;"
                                            label="Select medium of communication"
                                            v-model="selectedCommunicationMedium"></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex py-2">
                                        <div class="field-layout">
                                            <span>Select Gender</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <div class="d-flex country-code-value ml-2 align-center align-items-center" @click="updateSelectedGender('MALE')" :class="selectedGender === 'MALE' ? ' active-country' : 'country-code-value'">
                                                <div class="mr-1 mt-2">
                                                    <img src="https://s3iconimages.mymedicine.com.mm/male_sign.svg" alt="MM">
                                                </div>
                                                <div class="ml-1">
                                                    Male
                                                </div>
                                            </div>
                                            <div class="d-flex country-code-value ml-2 align-center align-items-center" @click="updateSelectedGender('FEMALE')" :class="selectedGender === 'FEMALE' ? ' active-country' : 'country-code-value'">
                                                <div class="mr-1 mt-2">
                                                    <img src="https://s3iconimages.mymedicine.com.mm/female_sign.svg" alt="MM">
                                                </div>
                                                <div class="ml-1">
                                                    Female
                                                </div>
                                            </div>
                                            <div class="d-flex country-code-value ml-2 align-center align-items-center" @click="updateSelectedGender('OTHER')" :class="selectedGender === 'OTHER' ? ' active-country' : 'country-code-value'">
                                                <div class="ml-1 mx-1">
                                                    Other
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex py-2 my-4 align-baseline">
                                        <div class="field-layout">
                                            <span>Specialization</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <v-select 
                                            :items="listCPISpecialization"
                                            solo
                                            style="height: 40px;"
                                            label="Select Specialization"
                                            item-value="_id"
                                            item-text="specialization_name"
                                            v-model="selectedCPISpecialization"
                                            @change="getDoctorForGivenSpecialization"></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex py-2 my-4 align-baseline">
                                        <div class="field-layout">
                                            <span>Doctor Name</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <v-select 
                                            :items="listCPIDoctor"
                                            solo
                                            style="height: 40px;"
                                            label="Select Doctor"
                                            item-value="_id"
                                            item-text="doctor_name"
                                            v-model="selectedCPIDoctor"
                                            @change="getTimeSlotDetailsForGivenDoctor"></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex py-2 my-4 align-baseline">
                                        <div class="field-layout">
                                            <span>Date Of Appointment</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <v-select 
                                            :items="listOfDateRange"
                                            solo
                                            style="height: 40px;"
                                            label="Select Date Of Appointment"
                                            item-disabled="slot_disabled"
                                            item-value="date_query"
                                            item-text="display_date"
                                            v-model="selectedDateOfAppointment"
                                            @change="getTimeSlot"></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex py-2 my-4 align-baseline">
                                        <div class="field-layout">
                                            <span>Select Time Slot</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <v-select 
                                            :items="listTimeSlot"
                                            solo
                                            style="height: 40px;"
                                            label="Select Time Slot"
                                            item-value="_id"
                                            item-text="displayTime"
                                            item-disabled="customDisabled"
                                            v-model="selectedTimeSlot"></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex py-2 my-4 align-baseline">
                                        <div class="field-layout">
                                            <span>Description</span>
                                        </div>
                                        <div class="d-flex value-layout">
                                            <v-textarea 
                                            solo 
                                            style="width: 100%;" 
                                            v-model="appointmentDescription" 
                                            rows="4"
                                            label="Enter the Reason to visit" 
                                            row-height="25"></v-textarea>
                                        </div>
                                    </div>
                                    <div class="py-2">
                                        <v-btn class="primary" @click="bookCPIAppointment" style="background-color: #1467BF;">
                                            Schedule Appointment
                                        </v-btn>
                                    </div>
                                </div>
                            </v-form>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-app>
    </div>
</template>
<script>
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';
export default {
    name: "CreateQuickHelpAppointment",
    components: {
        titleBar: () => import('../../navbar.vue'),
        sideNavBar: () => import('../../sideBar.vue'),
    },
    data() {
        return {
            pageName: 'Quick Help Appointment',
            currentUserName: null,
            currentUserId: null,
            listOfDateRange: [],
            listCPISpecialization: [],
            listCPIDoctor: [],
            selectedCPIDoctor: null,
            selectedCPISpecialization: null,
            selectedDateOfAppointment: null,
            selectedCountryCode: '+95',
            selectedCommunicationMedium:[],
            appointmentDescription: '',
            listOfPaymentStatus: [],
            selectedPaymentStatus: null,
            selectedGender: null,
            listTimeSlot: [],
            selectedTimeSlot: null,
            noSpecializationFound: false,
            selectedBirthYear: '',
            yearsList:[],
            phoneNumber: null,
            rules:{
                required: (value) => !!value|| 'This Field is Required',
                phoneNumber: (value) => (v && v.length <= 11 && v.length >=7) || 'Mobile Number should be between 7 to 11 numbers',
				notNegative: (value) => v > 0 || 'Mobile number cannot be negative',
            },
            listOfCommunicationMedium : ['SMS']
        }
    },
    beforeMount() {
        this.currentUser = this.$cookies.get("token");
        if (!this.currentUser) {
            this.$router.push({
                name: "AdminLogin",
            });
        } else {
            this.isTableItemsLoading = true;
            var getAdminBody = {
                token : this.currentUser,
                typeOfUser : "ADMIN",
                adminType: "ADMIN"
            };
            axios_auth_instance_admin.post("/getAdmin", getAdminBody)
            .then((getAdminResponse) => {
                this.currentUserName = getAdminResponse.data.data.admin_name;
                this.currentUserId = getAdminResponse.data.data._id;
            })
            .catch((getAdminError) => {
                handleError(getAdminError, this.$router, 'getAdmin', 'AdminLogin', 'token');
            });
        }
    },
    created() {
        const currentYear = new Date().getFullYear()
        const maxYear = currentYear - 3
        const startYear = 1900
        this.yearsList = Array.from({ length: maxYear - startYear + 1 }, (_, index) => maxYear - index )
    },
    mounted() {
        let reqParams = {
            token: this.currentUser,
            typeOfUser : "ADMIN"
        }
        axios_auth_instance_admin.post("/createAppointmentPage", reqParams)
        .then((createAppointmentPageResponse) => {
            console.log('Response: ', createAppointmentPageResponse);
            if (createAppointmentPageResponse.data.data.length === 0) {
                this.listCPISpecialization = [];
                this.noSpecializationFound = true;
            } else {
                this.listCPISpecialization = createAppointmentPageResponse.data.data;
                console.log(this.listCPISpecialization)
            }
        })
        .catch(( createAppointmentPageError) => {
            handleError(getAdminError, this.$router, 'getAdmin', 'AdminLogin', 'token');
        });
    },
    methods: {
        async bookCPIAppointment() {
            let bookCPIAppointmentObject = {
                slotId: this.selectedTimeSlot,
                doctorId: this.selectedCPIDoctor,
                specializationId: this.selectedCPISpecialization,
                countryCode: this.selectedCountryCode,
                communicationMedium: this.selectedCommunicationMedium,
                customerGender: this.selectedGender,
                phoneNumber: this.phoneNumber,
                birthYear: String(this.selectedBirthYear),
                appointmentDescription: this.appointmentDescription,
                token: this.currentUser,
                typeOfUser: "ADMIN"
            }
            console.log('Book Appointment Body', bookCPIAppointmentObject);
            axios_auth_instance_admin.post("/adminCreateQuickHelpAppointment", bookCPIAppointmentObject)
            .then((bookCPIAppointmentResponse) => {
                alert('Appointment Created SuccessFully');
                this.redirectToCPIAppointment();
            })
            .catch((bookCPIAppointmentError) => {
                alert('Failed to Book Appointment');
                this.redirectToCPIAppointment();
            })
        },
        updateCommunicationMedium( countryCode) {
            this.selectedCountryCode = countryCode;
            if ( this.selectedCountryCode === '+66') {
                this.listOfCommunicationMedium = ['VIBER', 'TELEGRAM']
            } else {
                this.listOfCommunicationMedium = ['SMS']
            }
        },
        updateSelectedGender(gender) {
            this.selectedGender = gender;
        },
        redirectToCPIAppointment() {
            this.$router.push({
                name: 'QuickHelpAppointmentList'
            })
        },
        getDoctorForGivenSpecialization() {
            let getDoctorRequestBody = {
                token: this.currentUser,
                typeOfUser: "ADMIN",
                specialization: this.selectedCPISpecialization
            };
            axios_auth_instance_admin.post("/getDoctorsBySpecialization", getDoctorRequestBody)
            .then((getDoctorsBySpecializationResponse) => {
                if (getDoctorsBySpecializationResponse.data.data.length === 0) {
                    this.listCPIDoctor = [];
                    alert('No Doctor Available for Selected Specialization !')
                } else {
                    this.listCPIDoctor = getDoctorsBySpecializationResponse.data.data;
                }
            })
            .catch((getDoctorsBySpecializationError) => {
                handleError(getDoctorsBySpecializationError, this.$router, 'getDoctorBySpecialization', 'AdminLogin', 'token');
            });
        },
        getTimeSlotDetailsForGivenDoctor() {
            this.listOfDateRange = [];
            this.selectedDateOfAppointment = null;
            var postData = {
                token: this.currentUser,
                typeOfUser: "ADMIN",
                doctorId: this.selectedCPIDoctor
            };
            axios_auth_instance_admin.post(process.env.VUE_APP_BACKEND_URL + "/getDateDisplay", postData)
            .then((getDateRangeResponse) => {
                console.log(getDateRangeResponse.data.data);
                for (var i = 0; i < getDateRangeResponse.data.data.length; i++) {
                    var strtoDate = new Date(
                    getDateRangeResponse.data.dataConverted[i]
                    );
                    this.listOfDateRange.push({
                        display_date: getDateRangeResponse.data.data[i],
                        date_query: getDateRangeResponse.data.dataConverted[i],
                        slot_disabled: getDateRangeResponse.data.buttonshow[i].isDateSlotPresent == "YES" ? false : true,
                        day: strtoDate.toLocaleString("en-us", { weekday: "short", }),
                    });
                }
            })
            .catch((getDateRangeError) => {
                handleError(getDateRangeError, this.$router, 'getDateRange', 'AdminLogin', 'token');
            });
        },
        getTimeSlot() {
            this.loading = true;
            var date_body = {
                doctorId: this.selectedCPIDoctor,
                dateOfAppointment: this.selectedDateOfAppointment,
                token: this.currentUser,
                typeOfUser: "ADMIN",
            };
            axios_auth_instance_admin.post( process.env.VUE_APP_BACKEND_URL + "/getTimeSlotDisplay", date_body )
            .then((getTimeSlotResponse) => {
                this.listTimeSlot = getTimeSlotResponse.data.data;
                this.listTimeSlot = getTimeSlotResponse.data.data.map((timeSlot) => ({
                    time_slot: timeSlot.time_slot,
                    _id: timeSlot._id,
                    customDisabled: timeSlot.counter <= 0 ? true : false,
                    displayTime: timeSlot.displayTime != '' && timeSlot.displayTime != undefined && timeSlot.displayTime != null ? timeSlot.displayTime : timeSlot.time_slot
                }));
            })
            .catch((getTimeSlotError) => {
                handleError(getTimeSlotError, this.$router, 'getTimeSlot', 'AdminLogin', 'token');
            });
        },
    }
}
</script>
<style scoped>
.country-code-value {
    border: 1px solid #C7C7C7;
    border-radius: 8px;
    padding: 8px;
}
.field-layout {
     width: 40%;
     text-align: left; 
}
.value-layout {
    width: 50%;
    text-align: left;
}
.backgorund-card {
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    margin: 12px 0px;
}
.active-country {
    background-color: #1467BF;
    color: white;
    border: 1px solid #C7C7C7;
}
.dialog-action-block {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px
}
</style>
